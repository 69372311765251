<template>
  <div>
    <b-row>
      <b-col><h3>Ihre Daten</h3></b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="12"
        ><b-form-input
          v-model="vorname"
          placeholder="Vorname *"
          class="mx-1 my-1"
        ></b-form-input
      ></b-col>
      <b-col lg="6" md="12"
        ><b-form-input
          v-model="nachname"
          placeholder="Nachname *"
          class="mx-1 my-1"
        ></b-form-input
      ></b-col>
      <b-col lg="6" md="12"
        ><b-form-input
          v-model="email"
          placeholder="Email *"
          class="mx-1 my-1"
        ></b-form-input
      ></b-col>
      <b-col lg="6" md="12"
        ><b-form-input
          v-model="telefon"
          placeholder="Telefon/Handynummer"
          class="mx-1 my-1"
        ></b-form-input
      ></b-col>
    </b-row>
    <b-row>
      <b-col>
        <span style="font-size: 10px; color: lightgray">* Pflichtfelder</span>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-input-group>
          <template v-slot:append>
            <b-input-group-text v-if="saveAnimation">
              <b-spinner
                class="spinnerSize"
                variant="dark"
                label="Lade..."
              ></b-spinner>
            </b-input-group-text>
            <b-input-group-text
              v-if="saveAnimation_Finish"
              :style="{ color: 'green' }"
            >
              <v-icon name="check-circle" scale="1" />
            </b-input-group-text>
          </template>
          <b-button @click="sendEmail" variant="outline-success"
            >Daten an {{ getMitarbeiterName }} senden</b-button
          >
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert
          :show="dismissCountDown"
          dismissible
          variant="danger"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
          class="mt-2"
        >
          <p>
            Fehler - Bitte fülle die Felder Vorname, Nachname und Email aus.
          </p>
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="6"
        ><b-button @click="zuDenFotos" variant="outline-dark"
          ><v-icon name="arrow-left" scale="1" /></b-button
      ></b-col>
    </b-row>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      templateParams: {
        from_name: "Hautseite",
        message: "",
        reply_to: "",
        to_name: "",
        to: "",
      },
      vorname: "",
      nachname: "",
      email: "",
      telefon: "",
      saveAnimation: false,
      saveAnimation_Finish: false,
      dismissCountDown: 0,
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    sendEmail() {
      if (this.vorname !== "" && this.nachname !== "" && this.email !== "") {
        this.saveAnimation = true;
        const currentUser = this.$store.getters.getCurrentUser;
        const Antworten = this.$store.getters.getAnwsers;
        const msg = [];

        msg.push("<br>Anfrage von:")
        msg.push(this.vorname + ' ' + this.nachname)
        msg.push('Email: ' + this.email)
        if (this.telefon) {
          msg.push('Telefon: ' + this.telefon)
        }
        Antworten.forEach((v) => {
          if (v) {
            msg.push("<br><br>Frage: " + v.Fragentext);
            if (Array.isArray(v.Antwort)) {
              msg.push("Antworten: ");
              v.Antwort.forEach((w) => {
                msg.push(w);
              });
            } else {
              msg.push("Antwort: " + v.Antwort);
            }
            if (v.Antwort_2) {
              if (Array.isArray(v.Antwort_2)) {
                msg.push("Zusatz:");
                v.Antwort_2.forEach((w) => {
                  msg.push(w);
                });
              } else {
                msg.push("Zusatz: " + v.Antwort_2);
              }
            }
            if (v.Antwort_3) {
              msg.push(v.Antwort_3);
            }
          }
        });

        msg.push(
          "<br><a href='" +
            this.$store.getters.getBildLinks +
            "' target='_blank'>Links Bild</img>"
        );
        msg.push(
          "<br><a href='" +
            this.$store.getters.getBildRechts +
            "' target='_blank'>Rechtes Bild</img>"
        );
        msg.push(
          "<br><a href='" +
            this.$store.getters.getBildVorderseite +
            "' target='_blank'>Vorderseite</img>"
        );

        this.templateParams.to_name = currentUser.MitarbeiterName;
        this.templateParams.to = currentUser.MitarbeiterEmail;
        this.templateParams.message = msg.join("<br>");
        emailjs
          .send(
            process.env.VUE_APP_EmailjsServiceID,
            process.env.VUE_APP_EmailjsTemplateID,
            this.templateParams,
            process.env.VUE_APP_EmailjsUserId
          )
          .then(
            () => {
              this.saveAnimation = false;
              this.saveAnimation_Finish = true;
              setTimeout(() => {
                this.saveAnimation_Finish = false;
              }, 1000);
            },
            () => {
              this.$bvToast.toast(
                "Email konnte nicht gesendet werden - Fehler",
                {
                  title: "Fehler",
                  autoHideDelay: 5000,
                }
              );
            }
          );
      } else {
        this.dismissCountDown = 5;
      }
    },
    zuDenFotos() {
      const currentUser = this.$store.getters.getCurrentUser;
      this.$router.replace({
        path: "/" + currentUser.MitarbeiterLink + "/Fotos",
      });
      this.$store.commit("setRvKey", Math.random());
    },
  },
  computed: {
    getMitarbeiterName() {
      return this.$store.getters.getCurrentUser.MitarbeiterName;
    },
  },
};
</script>
