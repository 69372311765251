<template>
  <b-container>
    <b-card class="mt-3" style="margin-bottom:100px;">
      <app-head />
      <b-row>
        <b-col>
          <abschluss />
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import abschluss from '@/components/Abschluss.vue'
import Head from "@/components/Head.vue";

export default {
  name: 'Fotos',
  components: {
    abschluss,
    appHead: Head,
  }
}
</script>
